<template>
  <a-modal
    :bodyStyle="{ padding: '16px 24px' }"
    :width="650"
    :visible="visible"
    title="安装说明"
    @cancel="handleClose"
    :destroy-on-close="true"
  >
    <template slot="footer">
      <a-button @click="handleClose" type="default">关闭</a-button>
    </template>
    <a-alert
      message="版本要求"
      style="border-radius: 8px; margin-bottom: 8px"
      type="info"
      banner
    >
      <template slot="description">
        <div>
          <b>Linux：</b>目前仅支持Linux 内核版本 2.12 及以上的 64 位版本。
        </div>
        <div>
          <b>Windows (PowerShell 3.0+):</b>目前仅支持Windows Server 2008 r2 及以上的 64 位版本。
        </div>
      </template>
    </a-alert>
    <div class="module-header-fs14" style="margin: 16px 0 8px 0">
      方式一：脚本安装
    </div>
    <div style="padding: 0 8px">
      <div style="position: relative">
        <h4>Linux：</h4>
        <a-tooltip title="点击复制">
          <a @click="copy('linux')" style="position: absolute; right: 0; top: 5px; font-size: 13px">
            <a-icon type="copy" />
          </a>
        </a-tooltip>
        <pre class="overflow-scroll-hidden"><code id="linux-code">curl {{serverAddress}}/api/v1/download?filename=install.sh | bash</code></pre>
      </div>
      <div style="position: relative">
        <h4>Windows：</h4>
        <a-tooltip title="点击复制">
          <a @click="copy('windows')" style="position: absolute; right: 0; top: 5px; font-size: 13px">
            <a-icon type="copy" />
          </a>
        </a-tooltip>
        <pre class="overflow-scroll-hidden"><code id="windows-code">Invoke-WebRequest {{serverAddress}}/api/v1/download?filename=install.ps1 | Select-Object -Expand Content | powershell</code></pre>
      </div>
    </div>

    <div class="module-header-fs14" style="margin: 24px 0 8px 0">
      方式二：安装包安装
    </div>
    <div style="padding: 0 8px">
      <h4 style="display: inline-block">Linux：</h4>
      <div style="display: inline-block">
        <a :href="`${serverAddress}/api/v1/download?filename=opseye_monitor_agent_linux.tar.gz`">
          opseye_monitor_agent_linux.tar.gz
        </a>
      </div>
      <br />
      <h4 style="display: inline-block">Windows：</h4>
      <div style="display: inline-block">
        <a :href="`${serverAddress}/api/v1/download?filename=opseye_monitor_agent_windows.zip`">
          opseye_monitor_agent_windows.zip
        </a>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { download } from '@/api/download'
import { mapGetters } from 'vuex'

export default {
  name: 'InstallModal',
  data () {
    return {
      visible: false
    }
  },
  computed: {
    ...mapGetters(['serverAddress'])
  },
  methods: {
    show () {
      this.visible = true
    },
    handleClose () {
      this.visible = false
    },
    copy (type) {
      const ele = type === 'linux' ? document.querySelector('#linux-code') : document.querySelector('#windows-code')
      const str = ele.innerText
      if (!navigator.clipboard) {
        var textArea = document.createElement('textarea')
        textArea.value = str
        textArea.style.position = 'fixed'
        document.body.appendChild(textArea)
        textArea.select()
        try {
          document.execCommand('copy')
          this.$message.success('已复制到剪贴板！')
        } catch (err) {
          this.$message.error('复制到剪贴板失败！请手动复制。')
        }
        document.body.removeChild(textArea)
      } else {
        navigator.clipboard.writeText(str)
          .then(() => {
            this.$message.success('已复制到剪贴板！')
          })
          .catch(() => {
            this.$message.error('复制到剪贴板失败！！请手动复制。')
          })
      }
    }
  }
}
</script>
