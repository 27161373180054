<template>
  <a-drawer
    :destroy-on-close="true"
    :title="isUpdate ? '操作系统编辑' : '操作系统创建'"
    :visible="visible"
    :width="800"
    @close="closeDrawer"
  >
    <a-alert
      :show-icon="true"
      style="margin-bottom: 16px; border-radius: 8px;"
      banner
      type="info"
    >
      <template slot="message">
        <div>默认通过 Agent 进行监控。一键安装请查看<a @click="$refs.installModal.show()"> 操作说明 </a>。</div>
      </template>
    </a-alert>

    <a-form-model
      ref="form"
      :label-col="{
        span: 4,
        style: {
          textAlign: 'left'
        }
      }"
      :model="form"
      :rules="rules"
      :wrapper-col="{
        span: 20
      }"
    >
      <a-form-model-item label="名称" prop="name">
        <a-input v-model="form.name" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item label="系统类型" prop="osType">
        <a-select
          v-model="form.osType"
          :disabled="isUpdate"
          :options="options"
        ></a-select>
      </a-form-model-item>
      <a-form-model-item prop="groups">
        <group-select v-model="form.groups" source-type="os"></group-select>
        <question-icon
          slot="label"
          title="所属群组"
          description="用于分组查询及告警配置"
        ></question-icon>
      </a-form-model-item>
      <a-form-model-item label="代理" prop="proxyId">
        <a-select
          v-model="form.proxyId"
          :options="proxyOptions"
        ></a-select>
      </a-form-model-item>
      <a-form-model-item label="监控地址" style="margin-bottom: 0">
        <div style="display: flex">
          <a-form-model-item prop="monitorAddress">
            <a-input
              v-model="form.monitorAddress"
              :disabled="isUpdate"
              :max-length="128"
            ></a-input>
          </a-form-model-item>
          <span style="margin: 0 4px">:</span>
          <a-form-model-item prop="monitorPort">
            <a-input
              v-model="form.monitorPort"
              :disabled="isUpdate"
              :max="65535"
              :min="1"
              type="number"
            ></a-input>
          </a-form-model-item>
        </div>
      </a-form-model-item>
      <a-form-model-item label="位置" prop="location">
        <a-input v-model="form.location"></a-input>
      </a-form-model-item>
      <a-form-model-item
        :wrapper-col="{ span: 20, offset: 4 }"
        style="margin-bottom: 0;"
      >
        <a-space size="middle">
          <a-button :loading="loading" type="primary" @click="ok">
            确定
          </a-button>
          <a-button @click="closeDrawer">取消</a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
    <install-modal ref="installModal"></install-modal>
  </a-drawer>
</template>

<script>
import { createOS, getOS, updateOS } from '@/api/os'
import { getMonitorProxyList } from '@/api/monitor'
import QuestionIcon from '@/components/icon/QuestionIcon'
import GroupSelect from '@/components/select/GroupSelect'
import { osTypes } from '@/utils/const'
import InstallModal from './InstallModal'

export default {
  name: 'InputDrawer',
  components: {
    GroupSelect,
    QuestionIcon,
    InstallModal
  },
  data () {
    return {
      visible: false,
      isUpdate: false,
      form: {
        id: '',
        name: '',
        osType: 'linux',
        groups: undefined,
        monitorAddress: '',
        monitorPort: 15000,
        location: '',
        proxyId: ''
      },
      rules: {
        name: [
          {
            message: '请输入名称',
            required: true,
            trigger: 'blur'
          }
        ],
        osType: [
          {
            message: '请选择系统类型',
            required: true,
            trigger: 'change'
          }
        ],
        monitorAddress: [
          {
            message: '请输入 IP 地址',
            required: true,
            trigger: 'blur'
          }
        ],
        monitorPort: [
          {
            message: '请输入监控端口',
            required: true,
            trigger: 'blur'
          }
        ],
        groups: [
          {
            message: '请选择所属群组',
            required: true,
            trigger: 'select'
          }
        ]
      },
      options: osTypes.map(value => {
        return {
          title: this.$t(`os_type.${value}`),
          value
        }
      }),
      proxyOptions: [],
      loading: false
    }
  },
  methods: {
    show (id) {
      getMonitorProxyList().then(res => {
        this.proxyOptions = res.data.data.map(item => {
          return {
            title: item.name,
            value: item.id
          }
        })
      })
      if (id) {
        this.isUpdate = true
        getOS(id)
          .then(res => {
            const data = res.data
            this.form = {
              id: data.id,
              name: data.name,
              osType: data.os_type,
              groups: data.groups,
              monitorAddress: data.monitor_address,
              monitorPort: data.monitor_port,
              location: data.location,
              proxyId: data.proxy_id
            }
          })
          .finally(() => {
            this.visible = true
          })
      } else {
        this.isUpdate = false
        this.visible = true
      }
    },
    closeDrawer () {
      this.form = {
        id: '',
        name: '',
        osType: 'linux',
        groups: undefined,
        monitorAddress: '',
        monitorPort: 15000,
        location: '',
        proxyId: ''
      }
      this.$refs.form.resetFields()
      this.visible = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const params = {
            name: this.form.name,
            location: this.form.location
          }
          if (this.form.groups) {
            params.group_ids = this.form.groups.map(group => group.id)
          }
          if (this.form.proxyId) {
            params.proxy_id = this.form.proxyId
          }
          this.loading = true
          if (this.isUpdate) {
            updateOS(this.form.id, params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.closeDrawer()
              })
              .finally(() => {
                this.loading = false
              })
          } else {
            params.os_type = this.form.osType
            params.monitor_address = this.form.monitorAddress
            params.monitor_port = this.form.monitorPort
            createOS(params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.closeDrawer()
              })
              .finally(() => {
                this.loading = false
              })
          }
        }
      })
    }
  }
}
</script>
